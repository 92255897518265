import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Rating,
  CircularProgress,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CustomEmojiPicker from "../customemojipicker";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { green } from "@mui/material/colors";
// import FeedBackFormPage2 from "./feedbackform page2";
import FeedBackFormProdPage2 from "./feedbackformpage2";
import { useMediaQuery } from '@mui/material';


const FeedBackFormPage1 = ({formExist,brandUrl,handleSubmit,formData,handleChange,handleRatingChange,open,handleClose, isFormSubmitted,lockForm,brand,setFormData}) => {
 


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    backgroundColor: "#F6F6F6",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };



  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // console.log(decodeURIComponent(searchParams.get("brand")));


  
 
 // Display loading screen

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Ensure vertical stacking
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#F6F6F6",
        position:'relative'
      }}
    >
      {formExist ? (
        <>
          <img
            src={brandUrl}
            alt="Brand Logo"
            width={"110px"}
            height={"80px"}
            style={{ marginBottom: "10px", marginTop: "10px" }}
          />
          <Card
            sx={{
              maxWidth: 600,
              width: "100%",
              padding: isSmallScreen?0:3,
              borderRadius: 2,
              boxShadow: "10px 10px #FFB22A", // Custom shadow with gold color
              pointerEvents: lockForm ? "none" : "auto",
              opacity: lockForm ? 0.5 : 1
            }}
          >
            <CardContent>
              <Typography variant="h4" gutterBottom>
                ✨ We'd love to hear from you
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                Hey there! You‘re here because you‘re an awesome {brand + " "}
                customer! 👏We’d love to receive your feedback so that we can
                improve.
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  gap: 2, // Adds spacing between form elements
                  alignItems: "stretch", // Ensures the form takes up the full width
                }}
              >
                <TextField
                  label="Your Name"
                  name="reviewer_name"
                  required
                  value={formData.reviewer_name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  placeholder="Enter your name"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Your Phone Number"
                      name="contact"
                      value={formData.contact}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      placeholder="Enter your phone number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Your Email ID"
                      name="user_email"
                      type="email"
                      value={formData.user_email}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      placeholder="Enter your email ID"
                    />
                  </Grid>
                </Grid>
                {/* Rating Component */}
                {/* <br></br> */}
                <Box style={{display:"flex",justifyContent:'center'}}>
                  <Rating
                    name="rating"
                    required
                    value={formData.rating}
                    onChange={handleRatingChange}
                    // size="large"
                    // precision={0.5}
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: "#FFB229", // Set the color for filled stars
                      },
                      "& .MuiRating-iconEmpty": {
                        color: "lightgray", // Set the color for empty stars
                      },
                      fontSize:"40px"
                    }}
                  />
                </Box>
                {/* <br></br> */}
                <Box style={{display:'flex',justifyContent:'space-between',width: isSmallScreen?"100%": "100%"}}>
                  <Typography>Food</Typography>
                  <Rating
                    name="food_rating"
                    required
                    value={formData.food_rating}
                    onChange={handleRatingChange}
                    size="large"
                    // precision={0.5}
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: "#FFB229", // Set the color for filled stars
                      },
                      "& .MuiRating-iconEmpty": {
                        color: "lightgray", // Set the color for empty stars
                      },
                    }}
                  />
                </Box>
                <Box style={{display:'flex',justifyContent:'space-between',width: isSmallScreen?"100%": "100%"}}>
                  <Typography>Service</Typography>
                  <Rating
                    name="service_rating"
                    required
                    value={formData.service_rating}
                    onChange={handleRatingChange}
                    size="large"
                    // precision={0.5}
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: "#FFB229", // Set the color for filled stars
                      },
                      "& .MuiRating-iconEmpty": {
                        color: "lightgray", // Set the color for empty stars
                      },
                    }}
                  />
                </Box>
                <Box style={{display:'flex',justifyContent:'space-between',width: isSmallScreen?"100%": "100%"}}>
                  <Typography>Atmosphere</Typography>
                  <Rating
                    name="atmoshphere_rating"
                    required
                    value={formData.atmoshphere_rating}
                    onChange={handleRatingChange}
                    size="large"
                    // precision={0.5}
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: "#FFB229", // Set the color for filled stars
                      },
                      "& .MuiRating-iconEmpty": {
                        color: "lightgray", // Set the color for empty stars
                      },
                    }}
                  />
                </Box>
                <TextField
                  label="Your Feedback"
                  name="review"
                  value={formData.review}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  placeholder="Enter your feedback"
                  multiline
                  rows={4} // Adjust the height of the text area
                />
                <br></br>
                <br></br>
                {/* <CustomEmojiPicker inputValue={formData.review} inputValueSetter={setFormData} /> */}
                <FeedBackFormProdPage2 formExist={formExist} brandUrl={brandUrl} handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} handleChange={handleChange} handleRatingChange={handleRatingChange} open={open} handleClose={handleClose} isFormSubmitted={isFormSubmitted} lockForm={lockForm} brand={brand} isSmallScreen={isSmallScreen}/>
                <Box
                sx={{
                    position: "fixed",                    
                    bottom: 20,  // Distance from the bottom of the screen
                    right: isSmallScreen?"5%":"0%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width:'100%'
                    }}>
                <Box
                  sx={{
                    zIndex: 9999,  // Ensure it appears above other content
                    width:'600px',
                    display: 'flex',
                    justifyContent: 'flex-end', 
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#FFB229",
                      textTransform:'None'
                    }}
                  >
                    Submit
                  </Button>
                </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, padding: 2 }}>
              {isFormSubmitted ? (
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CheckCircleOutlineIcon
                      sx={{
                        color: green[500],
                        fontSize: 60,
                        marginInline: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    Thank You!
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    Your feedback has been submitted successfully.
                  </Typography>
                </>
              ) : (
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CancelIcon
                      sx={{
                        color: green[500],
                        fontSize: 60,
                        marginInline: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    Feedback not submitted.
                  </Typography>
                </>
              )}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "#FFB229" }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <Typography variant="h6" color="textSecondary">
          Page Not Found
        </Typography>
      )}
    </Box>
  );
};

export default FeedBackFormPage1;
