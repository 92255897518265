import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Rating,
  CircularProgress,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CustomEmojiPicker from "../customemojipicker";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { green } from "@mui/material/colors";
import FeedBackFormPage1 from "./feedbackform1";
import FeedBackFormPage2 from "./feedbackform page2";

const FeedBackFormTest = () => {

  let [searchParams, setSearchParams] = useSearchParams();
  const [brandUrl, setBrandUrl] = useState("");
  const [formExist, setFormExist] = useState(true);
  const [loading, setLoading] = useState(true);
  const [lockForm, setlockForm] = useState(false);
  const brand = decodeURIComponent(searchParams.get("brand"));
  const [open, setOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    
    setOpen(false)
    window.location.reload();

  };

  const [formData, setFormData] = useState({
    reviewer_name: "",
    contact: "",
    user_email: "",
    review: "",
    rating: 0, // Added rating state
    food_rating: 0,
    atmoshphere_rating: 0,
    service_rating: 0,
    overAllExperience: [],
    offlineDeliveryType: "",
    mealTime: "",
    spendPerPerson: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRatingChange = (event, newValue) => {
    console.log(newValue);
    console.log(event.nativeEvent.target.name)
    const { name, value } = event.nativeEvent.target;
    console.log(name, value)
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  function processOverAllExperience(data) {
    // Check if 'overAllExperience' exists in the object
    if ('overAllExperience' in data) {
        const experienceArray = data.overAllExperience;

        // Step 1: Count occurrences of each value
        const countMap = {};
        experienceArray.forEach(item => {
            countMap[item] = (countMap[item] || 0) + 1;
        });

        // Step 2: Modify the array based on the counts
        const filteredArray = experienceArray.filter(item => {
            const count = countMap[item];

            // If the count is even, remove it from the array
            if (count % 2 === 0) {
                return false;
            }

            // If the count is odd, keep only one instance
            // Ensure to keep only the first occurrence and then remove others
            countMap[item] = 0;  // Mark that we have processed this item
            return true;
        });

        // Update the original array with the filtered array
        data.overAllExperience = filteredArray;

        // Return the updated object
        return data;
    } else {
        console.log('overAllExperience does not exist');
        return data;  // Return the original object if 'overAllExperience' is not found
    }
}

// Example usage:
// const data = {
//     "reviewer_name": "",
//     "contact": "",
//     "user_email": "",
//     "review": "",
//     "rating": 0,
//     "food_rating": 0,
//     "atmoshphere_rating": 0,
//     "service_rating": 0,
//     "overAllExperience": [
//         "Tasty",
//         "Fresh",
//         "Fresh",
//         "Tasty",
//         "Fresh",
//         "Unique"
//     ],
//     "offlineDeliveryType": "",
//     "mealTime": "",
//     "spendPerPerson": ""
// };

// const updatedData = processOverAllExperience(data);
// console.log(updatedData.overAllExperience);


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(e.target);
      if (formData.rating === 0) {
        // Handle the case where rating is not provided
        alert("Please provide a rating.");
        return;
      }

      setlockForm(true);

      const brandData = {
        restaurant_name: brand ? brand : "",
        sub_zone: decodeURIComponent(searchParams.get("subzone")),
        id: searchParams.get("id"),
        review_date: new Date().toISOString(),
        orderType: "dining",
        mealTime: "others",
      };
      // throw new Error();
      const newformData = { ...formData, ...brandData };
      const updatedData = processOverAllExperience(newformData);
// console.log(updatedData.overAllExperience);
      console.log("Form submitted:", updatedData);
      const url =
        "https://dev.restaverse.com/api/automation/insert-feedback-form-data";
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(updatedData),
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        // throw new Error(`HTTP error! status: ${response.status}`);
        console.log("something went wrong");
      } else {
        setFormData({
          // reviewer_name: "",
          // contact: "",
          // user_email: "",
          // review: "",
          // rating: 0, // Added rating state
          reviewer_name: "",
          contact: "",
          user_email: "",
          review: "",
          rating: 0, // Added rating state
          food_rating: 0,
          mealTime: "",
          atmoshphere_rating: 0,
          service_rating: 0,
          overAllExperience: [],
          offlineDeliveryType: "",
          spendPerPerson: ""
        });
      }
    } catch {
      setIsFormSubmitted(false);
    } finally {
      setOpen(true);
      setlockForm(false)
    }

    // }

    // Handle form submission logic here
  };



  useEffect(() => {
    const isFormValid = async () => {
      try {
        const formGuid = searchParams.get("guid");
        const sub_zone = decodeURIComponent(searchParams.get("subzone"));
        const id = searchParams.get("id");
        const response = await fetch(
          `https://dev.restaverse.com/api/automation/check-form-exist?guid=${formGuid}&subzone=${sub_zone}&id=${id}`
        );

        if (!response.ok) {
          console.log("hello");
          setFormExist(false);
        } else {
          const parsedResponse = await response.json();
          const logo = parsedResponse?.brand_logo || "";
          setBrandUrl(logo);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    isFormValid();
  }, []);


  useEffect(() => console.log(formData), [formData])
  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#F6F6F6",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );

  return (
    <div style={{ backgroundColor: "#F6F6F6", height: '100vh', width: '100%' }}>
      <div style={{ maxHeight: '900px', overflowY: 'auto', padding: '10px' }}>
        <FeedBackFormPage1 formExist={formExist} brandUrl={brandUrl} handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} handleChange={handleChange} handleRatingChange={handleRatingChange} open={open} handleClose={handleClose} isFormSubmitted={isFormSubmitted} lockForm={lockForm} brand={brand} />
        {/* <FeedBackFormPage2 formExist={formExist} brandUrl={brandUrl} handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} handleChange={handleChange} handleRatingChange={handleRatingChange} open={open} handleClose={handleClose} isFormSubmitted={isFormSubmitted} lockForm={lockForm} brand={brand} /> */}
      </div>
    </div>
  )

};

export default FeedBackFormTest;
