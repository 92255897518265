import React, { useEffect, useState,useRef } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Rating,
  CircularProgress,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CustomEmojiPicker from "../customemojipicker";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { green } from "@mui/material/colors";
import Option from "../option";
import Option2 from "../options foroverallexperience";


const FeedBackFormProdPage2 = ({formExist,brandUrl,handleSubmit,formData,handleChange,handleRatingChange,open,handleClose, isFormSubmitted,lockForm,brand,setFormData,isSmallScreen}) => {

  const initialState = ["Tasty", "Fresh", "Spicy", "Bland", "Overcooked", "Underseasoned", "Delicious", "Salty", "Flavourfull", "Clean", "Comfortable", "Slow", "Expensive", "Overpriced", "Budget Friendly", "Lousy"]
  const [overAllExperience, setOverAllExperience] = useState(initialState)

  const offlineDeliveryTypeHash = [{"Dine in":false}, {"Take out":false}, {"Delivery":false}]
  const [offlineDeliveryType, setOfflineDeliveryType] = useState([{"Dine in":false}, {"Take out":false}, {"Delivery":false}])

  const mealTimeHash = [{"Breakfast":false}, {"Brunch":false}, {"Lunch":false}, {"Dinner":false}, {"Other":false}]
  const [mealTime, setMealTime] = useState([{"Breakfast":false}, {"Brunch":false}, {"Lunch":false}, {"Dinner":false}, {"Other":false}])

  const spendPerPersonHash = [{"₹1-200":false}, {"₹200-400":false}, {"₹400-600":false}, {"₹600-800":false}, {"₹800-1000":false}, {"₹1000-1,200":false}, {"₹1,200-1,400":false}, {"₹1,400-1,600":false}, {"₹1,600-1,800":false}, {"₹1,800-2,000":false}, {"₹2,000+":false}];
  const [spendPerPerson, setSpendPerPerson] = useState([{"₹1-200":false}, {"₹200-400":false}, {"₹400-600":false}, {"₹600-800":false}, {"₹800-1000":false}, {"₹1000-1,200":false}, {"₹1,200-1,400":false}, {"₹1,400-1,600":false}, {"₹1,600-1,800":false}, {"₹1,800-2,000":false}, {"₹2,000+":false}])
  
  const [page2Response, setPage2Response] = useState({
    "overAllExperience": [],
    "offlineDeliveryType": [],
    "mealTime": [],
    "spendPerPerson": []
  })
  const [selectedOverAllExperience,setSelectedOverAllExperience] = useState([])
  const selectedOverAllExperienceRef = useRef([]);

  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    backgroundColor: "#F6F6F6",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box
    >
      {formExist ? (
        <>
          <div
            styles={{
              width: "100%",
              pointerEvents: lockForm ? "none" : "auto",
              opacity: lockForm ? 0.5 : 1,
            }}
          >
            <Box>
              <Box
                component="form"
                onSubmit={handleSubmit}
              >
                <Typography>
                  How you describe your overall experience?
                </Typography>
                <Box style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flexWrap: "wrap" }}>
                  {overAllExperience.map((item, index) => {
                    return (<Option2 item={item} setPage2Response={setFormData} kpi = {"overAllExperience"} setSelectedOverAllExperience={setSelectedOverAllExperience} />)
                  })
                  }
                </Box>
                <br></br>
                <Typography>
                  Did you dine in, take out, or get delivery?
                </Typography>
                <Box style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flexWrap: "wrap" }}>
                  {offlineDeliveryType.map((item, index) => {
                    return (<Option item={item} setPage2Response={setFormData} kpi = {"offlineDeliveryType"} initialState={offlineDeliveryTypeHash} setState={setOfflineDeliveryType}/>)
                  })
                  }
                </Box>
                <br></br>
                <Typography>
                  What did you get?
                </Typography>
                <Box style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flexWrap: "wrap" }}>
                  {mealTime.map((item, index) => {
                    return (<Option item={item} setPage2Response={setFormData} kpi= {"mealTime"} initialState={mealTimeHash} setState={setMealTime}/>)
                  })
                  }
                </Box>
                <br></br>
                <Typography>
                  How much did you spend per person?
                </Typography>
                <Box style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flexWrap: "wrap" }}>
                  {spendPerPerson.map((item, index) => {
                    return (<Option item={item} setPage2Response={setFormData} kpi={"spendPerPerson"} initialState={spendPerPersonHash} setState={setSpendPerPerson} />)
                  })
                  }
                </Box>
              </Box>
            </Box>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, padding: 2 }}>
              {isFormSubmitted ? (
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CheckCircleOutlineIcon
                      sx={{
                        color: green[500],
                        fontSize: 60,
                        marginInline: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    Thank You!
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    Your feedback has been submitted successfully.
                  </Typography>
                </>
              ) : (
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CancelIcon
                      sx={{
                        color: green[500],
                        fontSize: 60,
                        marginInline: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    Feedback not submitted.
                  </Typography>
                </>
              )}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "#FFB229" }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <Typography variant="h6" color="textSecondary">
          Page Not Found
        </Typography>
      )}
    </Box>
  );
};

export default FeedBackFormProdPage2;
