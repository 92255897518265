import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

const Option2 = ({ item, setPage2Response, kpi, setSelectedOverAllExperience }) => {
    const [selectedItems, setSelectedItems] = useState([]); // Track selected items
    const [isSelected, setIsSelected] = useState(false);
    const {name,currenState} = item
    const ClickHandler = () => {
        // 1. Optimistically update `selectedItems` state
        // setSelectedItems(prevSelectedItems => {
        //     const updatedSelectedItems = prevSelectedItems.includes(item)
        //         ? prevSelectedItems.filter(i => i !== item) // Remove if already selected
        //         : [...prevSelectedItems, item]; // Add if not selected
        //     return updatedSelectedItems;
        // });

        setIsSelected(prev => !prev);
        if (kpi === "overAllExperience") {
            setPage2Response(prevState => ({
                ...prevState,
                overAllExperience: [...prevState.overAllExperience, item]  // Adding new experience to the array
            }));
        }
        // else {
        //     if(!isSelected){
        //         setPage2Response(prevState => ({
        //             ...prevState,
        //             [kpi]: item  // Adding new experience to the array
        //         }));
        //     }
        //     else{
        //         setPage2Response(prevState => ({
        //             ...prevState,
        //             [kpi]: ""  // Adding new experience to the array
        //         }));
        //     }

        // }

        // // 2. Correctly update `setPage2Response` by using a functional update
        // setPage2Response(prevResponse => {
        //     // Create a new object to ensure immutability
        //     const newResponse = { ...prevResponse };  // Make a shallow copy

        //     if (kpi === "overAllExperience") {
        //         // If the selected item is "overAllExperience", we keep an array of items
        //         console.log(kpi)
        //         console.log(item)
        //         if (!newResponse[kpi]) {
        //             newResponse[kpi] = []; // Initialize if not yet set
        //         }

        //         // Add or remove item from the array
        //         if (!newResponse[kpi].includes(item)) {
        //             newResponse[kpi].push(item); // Add item if not already in the array
        //             setSelectedOverAllExperience(prev => [...prev,item])
        //         } else {
        //             // Remove item if already in the array
        //             newResponse[kpi] = newResponse[kpi].filter(i => i !== item);
        //             setSelectedOverAllExperience(prev => [...prev,item])
        //         }
        //         console.log(newResponse)
        //     } else {
        //         // For other kpis, just assign the content directly
        //         newResponse[kpi] = item;
        //     }

        //     // Return the new updated response (new state)
        //     return newResponse;
        // });
    };

    return (
        <span
            name={kpi}
            style={{
                border: '1px solid #7e8496',
                padding: '7px',
                borderRadius: '8px',
                color: "#7e8496",
                margin: '5px',
                backgroundColor: isSelected ? "#D9EAFD" : "", // Use `selectedItems` state
                cursor: 'pointer', // Pointer cursor for clickable items
            }}
            onClick={ClickHandler}
        >
            <Typography sx={{ fontWeight: 'bold' }}>
                {item}
            </Typography>
        </span>
    );
};

export default Option2;
