import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

const Option = ({ item, setPage2Response, kpi, setSelectedOverAllExperience,initialState,setState }) => {
    const [selectedItems, setSelectedItems] = useState([]); // Track selected items
    const [isSelected, setIsSelected] = useState(false);
    const [name,currenState] = Object.entries(item)[0]

    const ClickHandler = () => {

        setIsSelected(prev => !prev);
        if (kpi === "overAllExperience") {
            // const deepCopy = JSON.parse(JSON.stringify(initialState));

            setPage2Response(prevState => ({
                ...prevState,
                overAllExperience: [...prevState.overAllExperience, name]  // Adding new experience to the array
            }));
        }
        else {

            const deepCopy = JSON.parse(JSON.stringify(initialState));

            if(!isSelected){
                setPage2Response(prevState => ({
                    ...prevState,
                    [kpi]: name  // Adding new experience to the array
                }));

                 deepCopy.forEach(element => {

                    const [x,y] = Object.entries(element)[0]
                    // console.log(name,x)
                    if(x == name){
                        // console.log(name,x)
                        element[name]=true                    
                    }
                    }
                    
                );

                // console.log(deepCopy)

                setState(deepCopy)
                
            }
            else{
                setState(deepCopy)
                setPage2Response(prevState => ({
                    ...prevState,
                    [kpi]: ""  // Adding new experience to the array
                }));
            }

        }

    };

    return (
        <span
            name={kpi}
            style={{
                border: '1px solid #7e8496',
                padding: '7px',
                borderRadius: '8px',
                color: "#7e8496",
                margin: '5px',
                backgroundColor: currenState ? "#D9EAFD" : "", // Use `selectedItems` state
                cursor: 'pointer', // Pointer cursor for clickable items
            }}
            onClick={ClickHandler}
        >
            <Typography sx={{ fontWeight: 'bold' }}>
                {name}
            </Typography>
        </span>
    );
};

export default Option;
