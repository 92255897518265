import React, { useState } from "react";
import "./team.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import Button from "@mui/material/Button";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import AnimatedNumber from "../../Components/Team/animatednumber";
import useTheme from "@mui/system/useTheme";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import SuceessStory from "../../Components/SuccessStory/successstory";
import Ritik from "../../images/employees/Ritik.webp";
import sid from "../../images/employees/Sid.jpg";
import charmi from "../../images/employees/Charmi.webp";
import shiksha from "../../images/employees/Shiksha.webp";
import hritik from "../../images/employees/Hrithik.webp";
import sidcheesecake from "../../images/employees/Sid-cheesecakes.webp";
import urmi from "../../images/employees/Urmi.webp";
import valeron from "../../images/employees/Valeron.webp";
import arsh from "../../images/employees/Arsh 1.webp";
import rishon from "../../images/employees/Rishon.webp";
import akshay from "../../images/employees/akshay.png";
import prajwal from "../../images/employees/prajwal.png";
import vanishka from "../../images/employees/vanshika.png";
import amar from "../../images/employees/amar.png";
import manish from "../../images/employees/manish.png";
import yash from "../../images/employees/yash.png";

// import ritik from "../../images/employees/Khushi.webp"
// import ritik from "../../images/employees/Khushi.webp"

// const AnimatedNumber = ({ end, duration }) => {
//   const [ref, inView] = useInView({
//     triggerOnce: true,
//     delay: 200,
//   });

//   return (
//     <div ref={ref}>
//       <Typography variant="h2" color="primary">
//         {inView && (
//           <CountUp end={end} duration={duration} useGrouping={false} />
//         )}
//       </Typography>
//     </div>
//   );
// };

const TeamMember = ({ name, role, imageSrc, descriptions, linkedin }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
      <Card
        sx={{
          // height:'400px',
          maxWidth: "345px",
          borderRadius: "10px",
          position: "relative",
          fontFamily: "Poppins , sans-serif",
          "&:hover": {
            backdropFilter: "blur(10px)",
          },
        }}
        onMouseEnter={handleExpandClick}
        onMouseLeave={handleExpandClick}
      >
        <CardMedia
          component="img"
          height=""
          image={imageSrc}
          alt={`Image of ${name}`}
        />

        {/* Header outside the backdrop filter */}
        <CardHeader
          title={
            <Typography style={{ fontFamily: "Poppins , sans-serif" }}>
              {name}
            </Typography>
          }
          subheader={role}
          avatar={
            <div>
              <a href={linkedin} target="_blank" rel="noopener noreferrer">
                <LinkedInIcon fontSize="large" />
              </a>
            </div>
          }
          sx={{
            position: "relative", // Ensure the header stays above the backdrop filter
            zIndex: 1, // Set a higher zIndex to keep the header above other elements
            fontFamily: "Poppins , sans-serif",
          }}
        />

        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          sx={{ transformOrigin: "0 0" }}
        >
          <CardContent
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              backdropFilter: "blur(10px)",
              padding: "16px",
              transition: "backdropFilter 0.3s ease",
              fontFamily: "Poppins , sans-serif",
            }}
          >
            <Typography
              paragraph
              style={{
                lineHeight: 1.5,
                fontSize: 15,
                padding: "0% 2% 0% 2%",
                fontFamily: "Poppins , sans-serif",
              }}
            >
              {descriptions}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
};

const Team = () => {
  useEffect(() => {
    document.title = "Our Team | Restaverse";
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const itemsPerPage = 4;
  const teamMembers = [
    {
      name: "Siddharth",
      descriptions: "Sid is like a sommelier - he recommends the perfect solution to a complex problem. His weakness is a really good cheesecake.",
      imageSrc: sid,
      linkedin: "https://www.linkedin.com/in/siddharthjogani/",
    },
    {
      name: "Charmi",
      descriptions:
        "Charmi, with a palate for the perfect pani puri, leads the business with an eye for detail and a knack for perfection, ensuring every strategy is as meticulously crafted as her favorite street snack.",
      imageSrc: charmi,
      linkedin: "https://www.linkedin.com/in/charmi-jain-a7a9a8197/",
    },
    {
      name: "Shiksha",
      descriptions: "Shiksha, much like her preference for mac and cheese, combines comfort with skill in her work, creating solutions that are both satisfying and familiar, yet innovative, making complex tasks seem simple and enjoyable.",
      imageSrc: shiksha,
      linkedin: "https://www.linkedin.com/in/shikshachauhan/",
    },
    {
      name: "Amar",
      descriptions:
        "Amar, our Sales Head, closes deals like a perfectly grilled kebab – sizzling with charm, expertly seasoned with strategy, and always leaving clients satisfied.",
      imageSrc: amar,
      linkedin: "https://www.linkedin.com/in/amarr-prabodh-savla-5a533060/",
    },
    {
      name: "Prajval",
      descriptions:
        "Prajval, a Business Analyst, crunches numbers like a chef kneading dough – with precision, consistency, and always delivering the perfect base for data-driven decisions.",
      imageSrc: prajwal,
      linkedin: "https://www.linkedin.com/in/prajvalkataria/",
    },
    {
      name: "Yash",
      descriptions:
        "Yash, a Sales Executive, builds relationships like a street-side chaiwala – warm, approachable, and always creating moments that people remember.",
      imageSrc: yash,
      linkedin: "https://www.linkedin.com/in/yashbokadia/",
    },
    {
      name: "Arsh",
      descriptions: "As reliable as classic Margherita pizza, Arsh ensures every project is a perfect blend of simplicity and excellence.",
      imageSrc: arsh,
      linkedin: "https://www.linkedin.com/in/arshkhanna1/",
    },
    {
      name: "Rishon",
      descriptions: "Innovative like sushi, Rishon brings creativity and precision to every task, transforming ideas into delightful results.",
      imageSrc: rishon,
      linkedin: "https://www.linkedin.com/in/rishon-dharia-5aaa551b2/",
    },
    {
      name: "Vanshika",
      descriptions: "Vanshika, our Operations Manager, orchestrates tasks like a dosa master – ensuring every element is timed perfectly for smooth and flawless execution.",
      imageSrc: vanishka,
      linkedin: "https://www.linkedin.com/in/vanshika-rohra-353b13305/",
    },
    {
      name: "Manish",
      descriptions: "Manish, our Operations expert, manages workflows like a plate of rajma chawal – comforting, reliable, and always leaving everything in perfect balance.",
      imageSrc: manish,
      linkedin: "https://www.linkedin.com/in/manish-swami-491719184/",
    },
    {
      name: "Hritik",
      descriptions:
        "Hritik, a developer with a zest for Paneer Kolhapuri, brings the same rich and spicy complexity to his coding, serving up software solutions that are as bold and flavorful as his favorite dish.",
      imageSrc: hritik,
      linkedin: "https://www.linkedin.com/in/hritik-chauhan-3296651ba/",
    },
    {
      name: "Urmi",
      descriptions: "Flaky and delightful like a freshly baked croissant, Urmi adds a touch of elegance and reliability to every project.",
      imageSrc: urmi,
      linkedin: "https://www.linkedin.com/in/urmi-dangi-5a549b255/",
    },
    {
      name: "Valeron",
      descriptions: "Robust like a hearty lasagna, Valeron layers dedication and hard work to achieve outstanding success.",
      imageSrc: valeron,
      linkedin: "https://www.linkedin.com/in/valeron-toscano/",
    },
    {
      name: "Akshay",
      descriptions: "Akshay, a developer, writes code like a mithaiwala crafting sweets – intricate, delightful, and always a treat for the users.",
      imageSrc: akshay,
      linkedin: "https://www.linkedin.com/in/akshaygour/",
    },
    {
      name: "Ritik",
      descriptions: "Ritik , much like a momo enthusiast at an all-you-can-eat buffet, dives into problem-solving with a hunger for challenges and a knack for uncovering tasty solutions.",
      imageSrc: Ritik,
      linkedin:
        "https://www.linkedin.com/in/ritik-mohanty-109ab7135/?originalSubdomain=in",
    },


  ];

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  const calculateItemsPerRow = () => {
    const defaultScreenWidth = 1200; // Set a default screen width for initial render

    if (screenWidth === undefined) {
      return defaultScreenWidth >= 1200 ? 4 : defaultScreenWidth >= 600 ? 2 : 1;
    }

    return screenWidth >= 1200 ? 4 : screenWidth >= 600 ? 2 : 1;
  };
  return (
    <div>
      <section className="section-1">
        <h1
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: isSmallScreen ? "3.5em" : "5em",
          }}
        >
          Our team
        </h1>
        <div className="bar"></div>
      </section>
      <section className="section-2">
        <div style={{ marginBottom: "2%" }}>
          <Typography
            className="animate-from-top "
            variant={isSmallScreen ? "h6" : "h6"}
            style={{
              color: "4F4F4F",
              fontWeight: "bold",
              marginBottom: theme.spacing(3),
              fontFamily: "Poppins, sans-serif",
              textAlign: isSmallScreen ? "left" : "centre",
              margin: "0 auto",
              width: isSmallScreen ? "100%" : "60%",
            }}
          >
            Meet the Masterminds: Your Future Collaborators and Innovators
            Awaiting at Restaverse.
          </Typography>
        </div>

        <div className="container-lg">
          <Box
            display="flex"
            flexDirection="row-reverse" // Reverse the order of the flex items
            style={{
              textAlign: "left",
              display: "inline-block",
              width: "100%",
            }}
            mt={{ xs: 4, md: 4, lg: 4 }}
          >
            <Carousel
              slidesPerPage={4} // Adjust the number of slides per page
              animation="slide"
              timeout={1000}
              // indicatorIconButtonProps={{
              //   style: { backgroundColor: 'red' },

              // }}
              navButtonsAlwaysVisible={true}
              style={{
                width: "100%",
                justifyContent: "centre",
                alignItems: "centre",
              }}
              navButtonsWrapperProps={{
                // Move the buttons to the bottom. Unsetting top here to override default style.
                style: {
                  bottom: "0",
                  margin: "0px 0px",
                  height: "70%",
                  zIndex: 2,
                },
              }}
              navButtonsProps={
                {
                  // style: {
                  //   display: "none",
                  // },
                }
              }
              height="400px"
            >
              {Array.from({
                length: Math.ceil(teamMembers.length / calculateItemsPerRow()),
              }).map((_, index) => (
                <Grid container spacing={2} key={index}>
                  {teamMembers
                    .slice(
                      index * calculateItemsPerRow(),
                      (index + 1) * calculateItemsPerRow()
                    )
                    .map((member, i) => (
                      <TeamMember {...member} key={i} />
                    ))}
                </Grid>
              ))}
            </Carousel>
          </Box>
        </div>
        <div className="container-lg">
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }} // Reverse the order for small screens
            textAlign="left"
            mt={{ xs: 4, md: 6, lg: 9 }} // Adjust margin-top for small screens
            mb={{ xs: 4, md: 4, lg: 5 }}
          >
            {/* Left Side - Image */}
            <Box width={{ xs: "100%", md: "40%" }} mr={{ xs: 0, md: (0, 10) }}>
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className="img-fluid"
                  src={sidcheesecake}
                  alt=""
                  style={{
                    borderRadius: "40px 80px 40px 80px",
                    display: "inline-block",
                    objectFit: "cover",

                    width: "100%",
                    height: "375px",
                    // objectFit:'none',
                    //  objectPosition: "0% 0%",
                  }}
                />
              </div>
            </Box>

            <Box
              width={{ xs: "100%", md: "60%" }}
              p={{ xs: "30px 0px", md: 0 }}
            >
              <Typography
                variant="h2"
                gutterBottom
                style={{
                  animation: "fadeLeftText 1.5s forwards",
                  fontWeight: "550",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: isSmallScreen ? "2rem" : "3rem",
                  width: isSmallScreen ? "120%" : "100%",
                }}
              >
                We talk a lot about hope, helping, and teamwork
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph
                style={{
                  animation: "fadeLeftText 1.5s forwards",
                  // fontWeight: "bolder",
                  fontFamily: "Poppins , sans-serif",
                  fontSize: "1rem",
                }}
              >
                My goal is simple: to transform the restaurant world with smart
                predictions that help your business grow. My experience at
                Zomato, where I played a key role in expanding businesses across
                30+ cities and 6+ countries in APAC and Europe, taught me a lot
                about initiating and growing ventures. Now, I'm bringing all
                that knowledge to help you forecast and flourish. Let's cook up
                success together!
              </Typography>

              <Typography
                variant="body1"
                color="textSecondary"
                gutterBottom
                style={{
                  animation: "fadeRightText 1.5s forwards",
                  color: "",
                  fontSize: "1.1rem",
                  fontWeight: "bolder",
                  fontFamily: "Poppins , sans-serif",
                  "@media (max-width: 768px)": {
                    fontSize: "2.5em", // Adjust the font size for screens smaller than 768px
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "2em", // Adjust the font size for screens smaller than 600px
                  },
                  // Add more media queries as needed
                }}
              >
                Siddharth Jogani
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{
                  animation: "fadeRightText 1.5s forwards",
                  color: "",
                  fontSize: "1rem",
                  fontStyle: "italic",
                  fontFamily: "Poppins , sans-serif",
                  "@media (max-width: 768px)": {
                    fontSize: "2.5em", // Adjust the font size for screens smaller than 768px
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "2em", // Adjust the font size for screens smaller than 600px
                  },
                }}
              >
                Founder and CEO at <b>Restaverse </b>
              </Typography>
              {/* <Grid container spacing={1}>
              <Grid item sm={4}>
                <AnimatedNumber end={2023} duration={2} />
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "'Sen', sans-serif",
                  }}
                >
                  Launched
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <AnimatedNumber end={12} duration={2} unit={<span style={{fontSize:'2rem'}}> years</span>} />
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Poppins , sans-serif" }}
                >
                  Industry Exp.
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <AnimatedNumber end={14} duration={2} />
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Poppins , sans-serif" }}
                >
                  Cities
                </Typography>
              </Grid>
            </Grid> */}
            </Box>
          </Box>
        </div>
      </section>
      {/* <section className="join-team-section">
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-around"
          color="#fff"
          p={{ xs: 3, md: 10 }}
          marginTop={{ xs: 0, md: 0 }}
          // Adjust marginTop for small and medium/larger screens
        >
          <div>
            <Typography
              variant="h3"
              gutterBottom
              style={{ fontFamily: "'Sen', sans-serif" }}
            >
              Join our amazing team now
            </Typography>
            <Typography paragraph style={{ fontFamily: "'Sen', sans-serif" }}>
            Restaverse is looking for like-minded individuals. We prefer both humans and aliens 👽
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{ padding: "15px 30px", fontFamily: "'Sen', sans-serif",textTransform:'none',backgroundColor:'#6C41FF' }} // Adjust the padding values as needed

          >
            Join the team
          </Button>
        </Box>
      </section> */}
      <div className="container-lg">
        <SuceessStory
          titletext="Join our amazing team now 🚀"
          desctext="Restaverse is looking for like-minded individuals. We prefer both humans and aliens 👽"
          buttontext="Join the team"
          redirect="/about-us/join-us"
        />
      </div>
    </div>
  );
};

export default Team;
